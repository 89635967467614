import { inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { captureException } from '@sentry/angular';

import {
  Purchases,
  LOG_LEVEL,
  PurchasesStoreProduct,
} from '@revenuecat/purchases-capacitor';
import { Capacitor } from '@capacitor/core';

import { DataFirebaseService } from '@app/data-firebase.service';
import { AnalyticsService } from '@app/analytics.service';

export const InAppPurchaseCode = {
  ONE_TIME_MEMBERSHIP: 'com.live.tractorpal2.fullmember',
  SUBSCRIPTION_MEMBERSHIP: 'com.live.tractorpal2.fullmember.subscription',
} as const;

export type IAPProduct = PurchasesStoreProduct & { owned: boolean };

export interface IPurchasesService {
  initialize(userId: string): Promise<void>;
  restorePurchases(): Promise<void>;
  purchase(product: IAPProduct): Promise<void>;
}

const getApiKeyForPlatform = () => {
  if (Capacitor.getPlatform() === 'ios') {
    return 'appl_vNKSJWIESOjBkhEkwczdvMusaHG';
  } else if (Capacitor.getPlatform() === 'android') {
    return 'goog_odjAlRabrtZeyAIHEEwhGnqbcmB';
  }
  throw new Error('Unsupported platform');
};

@Injectable({
  providedIn: 'root',
})
export class PurchasesService implements IPurchasesService {
  private platform = inject(Platform);
  private dataService = inject(DataFirebaseService);

  constructor() {
    this.initialize().catch((error) => {
      captureException(error);
    });
  }

  private readonly analyticsService = inject(AnalyticsService);

  private membershipSubject = new ReplaySubject<
    (typeof InAppPurchaseCode)[keyof typeof InAppPurchaseCode] | null
  >(1);
  public readonly membership$ = this.membershipSubject.asObservable();

  private productsSubject = new ReplaySubject<IAPProduct[]>(1);
  public readonly products$ = this.productsSubject.asObservable();

  public async initialize() {
    console.log('[PurchasesService.initialize]');

    await this.platform.ready();

    await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
    await Purchases.configure({
      apiKey: getApiKeyForPlatform(),
      appUserID: await firstValueFrom(this.dataService.uid$),
    });

    await this.refreshProducts();
  }

  public async restorePurchases(): Promise<void> {
    try {
      await Purchases.restorePurchases();
      await this.refreshProducts();
      this.analyticsService.trackEvent('restore_purchases', {
        wasSuccessful: true,
      });
    } catch (error) {
      this.analyticsService.trackEvent('restore_purchases', {
        wasSuccessful: false,
        reason: error instanceof Error ? error.message : 'Unknown error',
      });
      throw error;
    }
  }

  public async purchase(product: PurchasesStoreProduct): Promise<void> {
    try {
      await Purchases.purchaseStoreProduct({
        product,
      });
      this.analyticsService.trackEvent('purchase', {
        title: product.title,
        productId: product.identifier,
        price: product.price,
        wasSuccessful: true,
      });
    } catch (error) {
      this.analyticsService.trackEvent('purchase', {
        title: product.title,
        productId: product.identifier,
        price: product.price,
        wasSuccessful: false,
        reason: error instanceof Error ? error.message : 'Unknown error',
      });
      throw error;
    }
  }

  private async refreshProducts() {
    const { products } = await Purchases.getProducts({
      productIdentifiers: [
        InAppPurchaseCode.SUBSCRIPTION_MEMBERSHIP,
        InAppPurchaseCode.ONE_TIME_MEMBERSHIP,
      ],
    });
    const {
      customerInfo: {
        entitlements: { all: allEntitlements },
      },
    } = await Purchases.getCustomerInfo();

    this.productsSubject.next(
      products.map((product) => ({
        ...product,
        owned:
          product.identifier in allEntitlements &&
          allEntitlements[product.identifier].isActive,
      })),
    );

    if (
      InAppPurchaseCode.SUBSCRIPTION_MEMBERSHIP in allEntitlements &&
      allEntitlements[InAppPurchaseCode.SUBSCRIPTION_MEMBERSHIP].isActive
    ) {
      this.membershipSubject.next(InAppPurchaseCode.SUBSCRIPTION_MEMBERSHIP);
    } else if (
      InAppPurchaseCode.ONE_TIME_MEMBERSHIP in allEntitlements &&
      allEntitlements[InAppPurchaseCode.ONE_TIME_MEMBERSHIP].isActive
    ) {
      this.membershipSubject.next(InAppPurchaseCode.ONE_TIME_MEMBERSHIP);
    } else {
      this.membershipSubject.next(null);
    }
  }
}

import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

type UserProfile = {
  id: string;
  email: string | undefined;
  displayName: string | undefined;
};

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private isEnabled = !environment.isWebVersion;

  /**
   * constructor
   * get mixpanel token and initialize
   */
  constructor() {
    this.init();
  }

  /**
   * Initialize mixpanel.
   */
  private init(): void {
    if (!this.isEnabled) {
      return;
    }

    mixpanel.init(environment.mixpanelToken, {
      debug: true,
      track_pageview: false,
      persistence: 'localStorage',
    });
  }

  /**
   * Identify User
   *
   * @param {string} alias
   */
  identify(profile: UserProfile) {
    if (!this.isEnabled) {
      return;
    }

    mixpanel.identify(profile.id);
    mixpanel.people.set({
      $name: profile.displayName,
      $email: profile.email,
    });
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    if (!this.isEnabled) {
      return;
    }

    mixpanel.track(id, action);
  }

  /**
   * setPeople
   * Store user profiles in Mixpanel's People Analytics product
   * @param {Object} properties
   */
  setPeople(properties: any = {}): void {
    if (!this.isEnabled) {
      return;
    }

    mixpanel.people.set(properties);
  }

  /**
   * sendEvent
   *
   * @param {string} event
   * @param {object} properties
   */
  trackEvent(event: string, properties: any = undefined) {
    if (!this.isEnabled) {
      return;
    }

    mixpanel.track(event, properties);
  }

  trackPageView() {
    if (!this.isEnabled) {
      return;
    }

    mixpanel.track_pageview();
  }

  /**
   * Reset Mixpanel
   */
  logout() {
    if (!this.isEnabled) {
      return;
    }

    mixpanel.reset();
  }
}
